import React, { useEffect, useState } from 'react';
import styles from './scss/Create.module.scss';
import { useForm } from 'react-hook-form';
import { otrs } from 'wrapper';

const CreateTicket = (props) => {
  const getPlayerInfo = (type) => {
    let game;

    const match = type.match(/:(.+)/);
    if (match) { game = match[1]; }
    const uid = player_id;

    if (uid.length < 4) return;

    otrs
      .get(`/support/autofill/player/${game}/${uid}/`)
      .then((resp) => {
        setValue('nickname', resp.nickname);
        clearErrors('player_id');
      })
      .catch((err) => {
        setError('player_id', { message: 'Player ID tidak ditemukan' });
        setValue('nickname', '');
      });
  };

  const populateGame = () => {
    let list = [];
    if (categories) {
      list = categories.map((dt, idx) => (
        <option key={idx} value={dt.id}>
          {dt.name}
        </option>
      ));
    }
    return [
      <option key={'default'} value="" disabled>
        Pilih Game
      </option>,
      ...list,
    ];
  };

  const populateCategory = () => {
    const el = document.querySelector('select[name="sub_category"]');
    let list = [];

    if (categories && category) {
      let filtered = categories.filter((dt) => dt.id === Number(category));
      let new_data = filtered[0];
      if (new_data) {
        el.removeAttribute('disabled');
        list = new_data.sub_category.map((dt) => (
          <option key={dt.id} value={dt.id}>
            {dt.name}
          </option>
        ));
      }
    }
    return [
      <option key={'default'} value="" disabled>
        Pilih Kategori
      </option>,
      ...list,
    ];
  };

  const populateSubCategory = () => {
    const el = document.querySelector('select[name="sub_sub_category"]');
    let list = [];

    if (categories && category && sub_category) {
      let filter1 = categories.filter((dt) => dt.id === Number(category));
      if (filter1.length > 0 && filter1[0].sub_category) {
        let filter2 = filter1[0].sub_category.filter((dt) => dt.id === Number(sub_category));
        let new_data = filter2[0];
        if (new_data) {
          el.removeAttribute('disabled');
          list = new_data.sub_sub_category.map((dt) => (
            <option key={dt.id} value={dt.id}>
              {dt.name}
            </option>
          ));
        }
      }
    }

    return [
      <option key={'default'} value="" disabled>
        Pilih Sub Kategori
      </option>,
      ...list,
    ];
  };

  const populateTemplate = (sub_sub_category) => {
    if (category && sub_category && sub_sub_category) {
      let filter1 = categories.filter((dt) => dt.id === Number(category));

      if (filter1.length > 0 && filter1[0].sub_category) {
        let filter2 = filter1[0].sub_category.filter((dt) => dt.id === Number(sub_category));

        if (filter2.length > 0 && filter2[0].sub_sub_category) {
          let filter3 = filter2[0].sub_sub_category.filter((dt) => dt.id === Number(sub_sub_category));

          setListForm(filter3[0]?.template);
        }
      }
    }
  };

  const populateUpload = (paramsName) => {
    return listFile.map((file, idx) => {
      let form_name = file.get('name');
      let name = file.get('filename');

      const isNameTooLong = name.length > 15;
      name = isNameTooLong ? '...' + name.substring(name.length - 15) : name;
      if(form_name === paramsName){
        return (
          <div key={idx} onClick={() => handleRemove(idx,paramsName)} className={`btn btn-small btn-secondary mr-2 ${styles.uploadList}`}>
            {name} <span className="badge badge-light ml-1">x</span>
          </div>
        );
      }else{
        return '';
      }
    });
  };

  const populateForm = (list) => {
    if (!list) return null;

    // const findNameSubCategory = () => {
    //   return categories.find((dt) => dt.id === Number(category))
    //     ?.sub_category.find((dt) => dt.id === Number(sub_category))
    //     ?.sub_sub_category.find((dt) => dt.id === Number(sub_sub_category))
    //     ?.name;
    // }

    return list.map((form) => {
      const { name, placeholder, sub_header, title, type, options } = form;
      // const gameIdsAutoInputNickname = [2, 3, 4, 5, 6]; // FF, AOV, SPEED, CODM
      // const nameSubCategory = findNameSubCategory();
      // const isAutoInputNickname = gameIdsAutoInputNickname.includes(category) && nameSubCategory !== "Akun terkena hack/Pemulihan akun";
      const isAutoInputNickname = true;

      if (type.includes('player_id')) {
        return (
          <div key={name} className={`form-group ${styles.group}`}>
            <label htmlFor={name}>{title}:</label>
            {sub_header && <div className={styles.subHeader}>{sub_header}</div>}
            <input
              type="text"
              {...register(name, {
                required: 'Silahkan masukan Player ID anda',
                validate: (value, formValues) => formValues.nickname !== '',
                onBlur: () => isAutoInputNickname && getPlayerInfo(type),
              })}
              placeholder={placeholder}
              className="form-control"
            />
            {errors[name] && <div className={styles.error}>{errors[name].message}</div>}
          </div>
        );
      }

      if (type === 'api_check_nickname') {
        return (
          <div key={name} className={`form-group ${styles.group}`}>
            <label htmlFor={name}>{title}:</label>
            {sub_header && <div className={styles.subHeader}>{sub_header}</div>}
            <input
              type="text"
              {...register(name, { required: isAutoInputNickname ? false : 'Silahkan masukan nickname anda' })}
              placeholder={placeholder}
              disabled={isAutoInputNickname}
              className="form-control"
            />
            {errors[name] && <div className={styles.error}>{errors[name].message}</div>}
          </div>
        );
      }

      if (type === 'input') {
        return (
          <div key={name} className={`form-group ${styles.group}`}>
            <label htmlFor={name}>{title}:</label>
            {sub_header && <div className={styles.subHeader}>{sub_header}</div>}
            <input
              type="text"
              {...register(name, { required: 'Silahkan masukan ' + title + ' anda' })}
              placeholder={placeholder}
              className="form-control"
            />
            {errors[name] && <div className={styles.error}>{errors[name].message}</div>}
          </div>
        );
      }

      if (type === 'email') {
        return (
          <div key={name} className={`form-group ${styles.group}`}>
            <label htmlFor={name}>{title}:</label>
            {sub_header && <div className={styles.subHeader}>{sub_header}</div>}
            <input
              type="text"
              {...register(name, {
                required: 'Silahkan masukan ' + title + ' anda',
                validate: {
                  matchPattern: (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email tidak valid',
                },
              })}
              placeholder={placeholder}
              className="form-control"
            />
            {errors[name] && <div className={styles.error}>{errors[name].message}</div>}
          </div>
        );
      }

      if (type === 'phone') {
        return (
          <div key={name} className={`form-group ${styles.group}`}>
            <label htmlFor={name}>{title}:</label>
            {sub_header && <div className={styles.subHeader}>{sub_header}</div>}
            <input
              type="text"
              {...register(name, {
                required: 'Silahkan masukan ' + title + ' anda',
                validate: {
                  matchPattern: (v) => /^[\d+() -]+$/.test(v) || 'Nomor telepon tidak valid',
                },
              })}
              placeholder={placeholder}
              className="form-control"
            />
            {errors[name] && <div className={styles.error}>{errors[name].message}</div>}
          </div>
        );
      }

      if (type === 'number') {
        return (
          <div key={name} className={`form-group ${styles.group}`}>
            <label htmlFor={name}>{title}:</label>
            {sub_header && <div className={styles.subHeader}>{sub_header}</div>}
            <input
              type="number"
              {...register(name, { required: 'Silahkan masukan ' + title + ' anda' })}
              placeholder={placeholder}
              className="form-control"
            />
            {errors[name] && <div className={styles.error}>{errors[name].message}</div>}
          </div>
        );
      }

      if (type === 'textarea') {
        return (
          <div key={name} className={`form-group ${styles.group} ${styles.textarea}`}>
            <label htmlFor={name}>{title}:</label>
            {sub_header && <div className={styles.subHeader}>{sub_header}</div>}
            <textarea
              {...register(name, 
                { required: 'Silahkan masukan ' + title + ' anda',
                onChange: (e) => {
                  const isValid = e.target.value.length < 1500
                  setValue('content', isValid ? e.target.value : e.target.value.slice(0, 1500))
                }
              })
            }
              placeholder={placeholder}
              rows={5}
              className="form-control"
              style={{ resize: 'none' }}
            />
            {errors[name] && <div className={styles.error}>{errors[name].message}</div>}
            <span className={styles.textLimit}>{text_content.length}/1500</span>
          </div>
        );
      }

      if (type === 'date_picker') {
        return (
          <div key={name} className={`form-group ${styles.group}`}>
            <label htmlFor={name}>{title}:</label>
            {sub_header && <div className={styles.subHeader}>{sub_header}</div>}
            <input type="date" {...register(name, { required: 'Silahkan masukan ' + title + ' anda' })} className="form-control" />
            {errors[name] && <div className={styles.error}>{errors[name].message}</div>}
          </div>
        );
      }

      if (type === 'dropdown') {
        const childDropdown = options.find((option) => option.name === watch(name, ''))?.template;
        return (
          <React.Fragment key={name}>
            <div className={`form-group ${styles.group}`}>
              <label htmlFor={name}>{title}:</label>
              {sub_header && <div className={styles.subHeader}>{sub_header}</div>}
              <select {...register(name, { required: 'Silahkan pilih ' + title + ' anda', value: '' })} className="form-control">
                <option key={'default'} value="" disabled>
                  {placeholder}
                </option>
                {options.map((option, i) => (
                  <option key={i} value={option.name}>
                    {option.title}
                  </option>
                ))}
              </select>
              {errors[name] && <div className={styles.error}>{errors[name].message}</div>}
            </div>
            {populateForm(childDropdown)}
          </React.Fragment>
        );
      }

      if (type === 'upload') {
        return (
          <React.Fragment key={name}>
            <div className={`form-group ${styles.group}`}>
              <label htmlFor="Upload File">{title}:</label>
              {sub_header && <div className={styles.subHeader}>{sub_header}</div>}
              <input
                type="file"
                {...register(name, { required: 'Silahkan upload file anda' })}
                className={`form-control-file fileUpload-${name}`}
                id={`fileUpload-${name}`}
                onChange={(e)=>handleUpload(e,name)}
              />
              <p className={styles.helpText}>* Only jpg, jpeg, png or pdf files are allowed, Maximum file size 3MB</p>
              {errors[name] && <div className={styles.error}>{errors[name].message}</div>}
            </div>
            <div className={styles.uploadListCont}>{populateUpload(name)}</div>
          </React.Fragment>
        );
      }

      return null;
    });
  };

  const handleUpload = (e,name) => {
    e.preventDefault();

    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf']

    const checkFileType = (file) => allowedTypes.includes(file.type);
    const checkFileExistence = (file, list) => list.find((form) => form.get('filename') === file.name);

    const createFormData = (file) => {
      let formData = new FormData();
      formData.append('cvfile', file);
      formData.append('type', 'otrs_file');
      formData.append('filename', file.name);
      formData.append('name',name);
      return formData;
    };

    let file = e.currentTarget.files[0];

    if (!file?.name) return;

    if (!checkFileType(file)) {
      showAlert('MAAF!', 'Hanya file jpg, jpeg, png atau pdf yang diperbolehkan.');
      return;
    }

    const isDuplicate = checkFileExistence(file, listFile);
    
    if (isDuplicate) {
      showAlert('MAAF!', 'File sudah ada');
      return;
    }

    const formData = createFormData(file);
    listFile.push(formData);

    updateListFile(listFile);

    clearErrors('attachment');  
  };
  const handleRemove = (idx,name) => {
    const temp = [...listFile];

    temp.splice(idx, 1);

    updateListFile(temp);
    
    document.getElementById(`fileUpload-${name}`).value = '';      
  };

  const handleChangeDropdown = (type) => {
    if (type === 'category') {
      resetField('sub_category');
      resetField('sub_sub_category');
    }

    if (type === 'sub_category') {
      resetField('sub_sub_category');
    }

    if (type === 'sub_sub_category') {
    }

    // Clear prev values
    listForm.forEach((data) => resetField(data.name));
    setListForm([]);
  };

  const onSubmit = (data) => {
    const grecaptcha = window.grecaptcha;
    const formdata = new FormData();

    grecaptcha.ready(function () {
      grecaptcha.execute('6LeSmOMUAAAAAHnLh6JzRDbLCNVfTPTVMO9fOcEA', { action: 'homepage' }).then(function (token) {
        formdata.append('g-recaptcha-response', token);

        for (const key in data) {
          formdata.append(key, data[key]);
        }

        updateFormData(formdata);
        toggleModalSubmit();
      });
    });
  };

  const { user, categories, listFile, updateListFile,toggleModalSubmit, updateFormData, clearFormData, setClearFormData, showAlert } = props;
  const [listForm, setListForm] = useState([]);
  const {
    register,
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
    resetField,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: { username: user.nickname } });
  const category = watch('category', '');
  const sub_category = watch('sub_category', '');
  // const sub_sub_category = watch('sub_sub_category', '');
  const player_id = watch('player_id', '');
  const text_content = watch('content', '');
  useEffect(() => {
    updateListFile([]);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!clearFormData) return;

    reset();
    setListForm([]);
    updateListFile([]);
    updateFormData(false);

    setClearFormData(false);

    // eslint-disable-next-line
  }, [clearFormData]);

  return (
    <div id="CreateTicket" className="container pt-4 pb-5">
      <div className="row">
        <div className="col-md-6">
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={`form-group ${styles.group}`}>
              <label htmlFor="username">Username:</label>
              <input type="text" {...register('username')} readOnly className="form-control" />
            </div>
            <div className={`form-group ${styles.group}`}>
              <label htmlFor="category">Game:</label>
              <select
                {...register('category', {
                  required: 'Silahkan pilih game terlebih dahulu',
                  value: '',
                  valueAsNumber: true,
                  onChange: () => handleChangeDropdown('category'),
                })}
                className="form-control">
                {populateGame()}
              </select>
              {errors['category'] && <div className={styles.error}>{errors['category'].message}</div>}
            </div>
            <div className={`form-group ${styles.group}`}>
              <label htmlFor="sub_category">Kategori:</label>
              <select
                {...register('sub_category', {
                  required: 'Silahkan pilih kategori terlebih dahulu',
                  value: '',
                  valueAsNumber: true,
                  onChange: () => handleChangeDropdown('sub_category'),
                })}
                disabled
                className="form-control">
                {populateCategory()}
              </select>
              {errors['sub_category'] && <div className={styles.error}>{errors['sub_category'].message}</div>}
            </div>
            <div className={`form-group ${styles.group}`}>
              <label htmlFor="sub_sub_category">Sub Kategori:</label>
              <select
                {...register('sub_sub_category', {
                  required: 'Silahkan pilih sub kategori terlebih dahulu',
                  value: '',
                  valueAsNumber: true,
                  onChange: (e) => {
                    handleChangeDropdown('sub_sub_category');
                    populateTemplate(e.target.value);
                  },
                })}
                disabled
                className="form-control">
                {populateSubCategory()}
              </select>
              {errors['sub_sub_category'] && <div className={styles.error}>{errors['sub_sub_category'].message}</div>}
            </div>
            {populateForm(listForm)}
            <input type="submit" value="Kirim" className={`btn btn-danger ${styles.submit}`} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateTicket;
